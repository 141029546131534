import axios from 'axios'
import { endpoint } from '@/common/constants'
import resource from '@/api/resourceChatbot'

const AxiosCancelToken = axios.CancelToken
const NAMESPACE = 'CHATGPT'

export default {
  /**
   * @description Generate summary
   * @param {String} roomID
   * @returns {Promise}
   */
  generateSummary(roomID) {
    let cancel
    const CANCEL_TOKEN = `${NAMESPACE}_GENERATE_SUMMARY`
    const request = resource.post(
      endpoint.v1.chatbot.gpt.summary.generate.replace(':room_id', roomID),
      {},
      {
        cancelToken: new AxiosCancelToken((cancelRequest) => {
          cancel = cancelRequest
        }),
      }
    )

    window[CANCEL_TOKEN] = cancel

    return request
  },

  /**
   * @description Retrieve summary
   * @param {String} roomID
   * @param {String} ID
   * @returns {Promise}
   */
  retrieveSummary(roomID, ID) {
    let cancel
    const CANCEL_TOKEN = `${NAMESPACE}_RETRIEVE_SUMMARY`
    const request = resource.get(
      `${endpoint.v1.chatbot.gpt.summary.retrieve.replace(
        ':room_id',
        roomID
      )}/${ID}`,
      {
        cancelToken: new AxiosCancelToken((cancelRequest) => {
          cancel = cancelRequest
        }),
      }
    )

    window[CANCEL_TOKEN] = cancel

    return request
  },

  /**
   * @description Save summary
   * @param {Object} payload
   * @returns {Promise}
   */
  saveSummary(payload) {
    let cancel
    const CANCEL_TOKEN = `${NAMESPACE}_SAVE_SUMMARY`
    const request = resource.post(
      endpoint.v1.chatbot.gpt.summary.save,
      payload,
      {
        cancelToken: new AxiosCancelToken((cancelRequest) => {
          cancel = cancelRequest
        }),
      }
    )

    window[CANCEL_TOKEN] = cancel

    return request
  },

  /**
   * @description Get summary
   * @param {String} roomID
   * @returns {Promise}
   */
  getSummary(roomID) {
    let cancel
    const CANCEL_TOKEN = `${NAMESPACE}_GET_SUMMARY`
    const request = resource.get(
      endpoint.v1.chatbot.gpt.summary.get.replace(':room_id', roomID),
      {
        cancelToken: new AxiosCancelToken((cancelRequest) => {
          cancel = cancelRequest
        }),
      }
    )

    window[CANCEL_TOKEN] = cancel

    return request
  },

  /**
   * @description Remove summary
   * @param {String} roomID
   * @returns {Promise}
   */
  removeSummary(roomID) {
    let cancel
    const CANCEL_TOKEN = `${NAMESPACE}_REMOVE_SUMMARY`
    const request = resource.delete(
      endpoint.v1.chatbot.gpt.summary.remove.replace(':room_id', roomID),
      {
        cancelToken: new AxiosCancelToken((cancelRequest) => {
          cancel = cancelRequest
        }),
      }
    )

    window[CANCEL_TOKEN] = cancel

    return request
  },

  /**
   * @description Paraphrase text
   * @param {String} text
   * @param {String} action
   * list of action: longer, improve, translate_EN, translate_ID
   * @param {String} roomID
   * @returns {Promise}
   */
  paraphrase(text, action, roomID) {
    let cancel
    const CANCEL_TOKEN = `${NAMESPACE}_PARAPHRASE`
    const request = resource.get(endpoint.v1.chatbot.gpt.paraphrases.get, {
      params: {
        text,
        action,
        room_id: roomID,
      },
      cancelToken: new AxiosCancelToken((cancelRequest) => {
        cancel = cancelRequest
      }),
    })

    window[CANCEL_TOKEN] = cancel

    return request
  },

  /**
   * @description Generate Paraphrase
   * @param {String} text
   * @param {String} action
   * list of action: longer, improve, translate_EN, translate_ID
   * @param {String} roomID
   * @returns {Promise}
   */
  generateParaphrase(text, action, roomID, tone) {
    let cancel
    const CANCEL_TOKEN = `${NAMESPACE}_GENERATE_PARAPHRASE`
    const request = resource.post(
      endpoint.v1.chatbot.gpt.paraphrases.generate.replace(':room_id', roomID),
      {
        action,
        text,
        tone,
      },
      {
        cancelToken: new AxiosCancelToken((cancelRequest) => {
          cancel = cancelRequest
        }),
      }
    )

    window[CANCEL_TOKEN] = cancel

    return request
  },

  /**
   * @description Retrieve Paraphrase
   * @param {String} roomID
   * @param {String} ID
   * @returns {Promise}
   */
  retrieveParaphrase(roomID, ID) {
    let cancel
    const CANCEL_TOKEN = `${NAMESPACE}_RETRIEVE_PARAPHRASE`
    const request = resource.get(
      `${endpoint.v1.chatbot.gpt.paraphrases.retrieve.replace(
        ':room_id',
        roomID
      )}/${ID}`,
      {
        cancelToken: new AxiosCancelToken((cancelRequest) => {
          cancel = cancelRequest
        }),
      }
    )

    window[CANCEL_TOKEN] = cancel

    return request
  },

  getPreferences() {
    let cancel
    const CANCEL_TOKEN = `${NAMESPACE}_GET_PREFERENCES`
    const request = resource.get(`${endpoint.v1.chatbot.gpt.preferences.get}`, {
      params: {
        group_code: 'paraphrase',
        code: 'tone',
        enabled: true,
      },
      cancelToken: new AxiosCancelToken((cancelRequest) => {
        cancel = cancelRequest
      }),
    })

    window[CANCEL_TOKEN] = cancel

    return request
  },

  setPreferences(params) {
    let cancel
    const CANCEL_TOKEN = `${NAMESPACE}_SET_PREFERENCES`
    const payload = {
      group_code: 'paraphrase',
      code: 'tone',
      enabled: true,
      value: params,
    }
    const request = resource.patch(
      `${endpoint.v1.chatbot.gpt.preferences.patch}`,
      payload,
      {
        cancelToken: new AxiosCancelToken((cancelRequest) => {
          cancel = cancelRequest
        }),
      }
    )

    window[CANCEL_TOKEN] = cancel

    return request
  },

  getHistoricalSummarize(params) {
    let cancel
    const CANCEL_TOKEN = `${NAMESPACE}_GET_HISTORICAL_SUMMARIZE`
    const request = resource.get(
      endpoint.v1.chatbot.gpt.historical.get.replace(':id', params.contact_id),
      {
        params,
        cancelToken: new AxiosCancelToken((cancelRequest) => {
          cancel = cancelRequest
        }),
      }
    )

    window[CANCEL_TOKEN] = cancel

    return request
  },

  getSubscription(params) {
    let cancel
    const CANCEL_TOKEN = `${NAMESPACE}_GET_SUBSCRIPTION`
    const request = resource.get(endpoint.v1.chatbot.gpt.subsctiption.get, {
      params,
      cancelToken: new AxiosCancelToken((cancelRequest) => {
        cancel = cancelRequest
      }),
    })

    window[CANCEL_TOKEN] = cancel

    return request
  },
}
