import axios from 'axios'
import { endpoint } from '@/common/constants'
import resource from '@/api/resource'

const AxiosCancelToken = axios.CancelToken
const NAMESPACE = 'HARMONIA'

export default {
  /**
   * @description check if harmonia form is submitted
   * @param {string} formName
   * @param {string} companyID
   * @returns {Promise}
   */
  isHarmoniaFormSubmitted(formName, companyID) {
    if (!formName || !companyID) {
      return Promise.reject(new Error('formName and companyID are required'))
    }

    let cancel
    const CANCEL_TOKEN = `${NAMESPACE}_IS_HARMONIA_FORM_SUBMITTED`
    const request = resource.get(endpoint.v1.harmonia.is_form_submitted, {
      params: {
        form_name: formName,
        company_id: companyID,
      },
      cancelToken: new AxiosCancelToken((cancelRequest) => {
        cancel = cancelRequest
      }),
    })

    window[CANCEL_TOKEN] = cancel

    return request
  },
}
