import axios from 'axios'
import { endpoint } from '@/common/constants'
import resource from '@/api/resourceChatbot'

const AxiosCancelToken = axios.CancelToken
const NAMESPACE = 'SCORECARD'

export default {
  /**
   * @description get scorecard list
   * @returns {Promise}
   */
  get(params) {
    let cancel
    const CANCEL_TOKEN = `${NAMESPACE}_GET_LIST`
    const request = resource.get(`${endpoint.v1.chatbot.scorecard.get}`, {
      params: {
        page: params.page,
        limit: params.limit,
        order_by: params.name,
        order_direction: params.direction,
      },
      cancelToken: new AxiosCancelToken((cancelRequest) => {
        cancel = cancelRequest
      }),
    })

    window[CANCEL_TOKEN] = cancel

    return request
  },

  /**
   * @description get parameter list
   * @returns {Promise}
   */
  getParameters() {
    let cancel
    const CANCEL_TOKEN = `${NAMESPACE}_GET_PARAMETER_LIST`
    const request = resource.get(
      `${endpoint.v1.chatbot.scorecard.getParameters}`,
      {
        params: {
          page: 1,
          limit: 100,
          order_by: 'code',
        },
        cancelToken: new AxiosCancelToken((cancelRequest) => {
          cancel = cancelRequest
        }),
      }
    )

    window[CANCEL_TOKEN] = cancel

    return request
  },

  /**
   * @description create new scorecard
   * @returns {Promise}
   */
  create(params) {
    let cancel
    const CANCEL_TOKEN = `${NAMESPACE}_CREATE_SCORECARD`
    const payload = { ...params }
    const request = resource.post(
      endpoint.v1.chatbot.scorecard.create,
      payload,
      {
        cancelToken: new AxiosCancelToken((cancelRequest) => {
          cancel = cancelRequest
        }),
      }
    )

    window[CANCEL_TOKEN] = cancel

    return request
  },

  /**
   * @description update scorecard by id
   * @returns {Promise}
   */
  update(params) {
    let cancel
    const CANCEL_TOKEN = `${NAMESPACE}_UPDATE_SCORECARD`
    const payload = { ...params }
    const request = resource.patch(
      `${endpoint.v1.chatbot.scorecard.update.replace(':id', params.id)}`,
      payload,
      {
        cancelToken: new AxiosCancelToken((cancelRequest) => {
          cancel = cancelRequest
        }),
      }
    )

    window[CANCEL_TOKEN] = cancel

    return request
  },

  /**
   * @description update scorecard by id
   * @returns {Promise}
   */
  delete(params) {
    let cancel
    const CANCEL_TOKEN = `${NAMESPACE}_DELETE_SCORECARD`
    const payload = { ...params }
    const request = resource.delete(
      `${endpoint.v1.chatbot.scorecard.update.replace(':id', params.id)}`,
      payload,
      {
        cancelToken: new AxiosCancelToken((cancelRequest) => {
          cancel = cancelRequest
        }),
      }
    )

    window[CANCEL_TOKEN] = cancel

    return request
  },

  createScorecardRoom(params) {
    let cancel
    const CANCEL_TOKEN = `${NAMESPACE}_CREATE_SCORECARD_BY_ROOM`
    const payload = { categories: params.categories }
    const request = resource.post(
      `${endpoint.v1.chatbot.scorecard.createByRoom.replace(':id', params.id)}`,
      payload,
      {
        cancelToken: new AxiosCancelToken((cancelRequest) => {
          cancel = cancelRequest
        }),
      }
    )

    window[CANCEL_TOKEN] = cancel

    return request
  },

  updateScorecardRoom(params) {
    let cancel
    const CANCEL_TOKEN = `${NAMESPACE}_UPDATE_SCORECARD_BY_ROOM`
    const payload = { categories: params.categories }
    const request = resource.patch(
      `${endpoint.v1.chatbot.scorecard.updateByRoom.replace(':id', params.id)}`,
      payload,
      {
        cancelToken: new AxiosCancelToken((cancelRequest) => {
          cancel = cancelRequest
        }),
      }
    )

    window[CANCEL_TOKEN] = cancel

    return request
  },

  /**
   * @description get scorecard by room id
   * @returns {Promise}
   */
  getByRoom(params) {
    let cancel
    const CANCEL_TOKEN = `${NAMESPACE}_GET_SCORECARD_BY_ROOM`
    const request = resource.get(
      `${endpoint.v1.chatbot.scorecard.getByRoom.replace(':id', params.id)}`,
      {
        cancelToken: new AxiosCancelToken((cancelRequest) => {
          cancel = cancelRequest
        }),
      }
    )

    window[CANCEL_TOKEN] = cancel

    return request
  },

  getPreferences(params) {
    let cancel
    const CANCEL_TOKEN = `${NAMESPACE}_GET_PREFERENCES`
    const request = resource.get(
      `${endpoint.v1.chatbot.scorecard.preferences}`,
      {
        params,
        cancelToken: new AxiosCancelToken((cancelRequest) => {
          cancel = cancelRequest
        }),
      }
    )

    window[CANCEL_TOKEN] = cancel

    return request
  },

  updatePreferences(params) {
    let cancel
    const CANCEL_TOKEN = `${NAMESPACE}_UPDATE_PREFERENCES`
    const payload = { ...params }
    const request = resource.patch(
      `${endpoint.v1.chatbot.scorecard.preferences}`,
      payload,
      {
        cancelToken: new AxiosCancelToken((cancelRequest) => {
          cancel = cancelRequest
        }),
      }
    )

    window[CANCEL_TOKEN] = cancel

    return request
  },

  /**
   * @description get scorecard by room id in array
   * @returns {Promise}
   */
  getByRoomIds(params) {
    let cancel
    const CANCEL_TOKEN = `${NAMESPACE}_GET_SCORECARD_BY_ROOM_IDS`
    const request = resource.get(endpoint.v1.chatbot.scorecard.getByRoomIds, {
      params,
      cancelToken: new AxiosCancelToken((cancelRequest) => {
        cancel = cancelRequest
      }),
    })

    window[CANCEL_TOKEN] = cancel

    return request
  },
}
