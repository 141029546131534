import axios from 'axios'
import { endpoint } from '@/common/constants'
import resource from '@/api/resource'

const AxiosCancelToken = axios.CancelToken
const NAMESPACE = 'USER'

export default {
  /**
   * @description Update introduced_smart_assist_status flag
   * @param {Object} payload
   * @returns {Promise}
   */
  updateIntroducedSmartAssistStatusFlag(payload) {
    let cancel
    const CANCEL_TOKEN = `${NAMESPACE}_UPDATE_INTRODUCED_SMART_ASSIST_STATUS_FLAG`
    const request = resource.post(
      endpoint.v1.user.introduced_smart_assist_status,
      payload,
      {
        cancelToken: new AxiosCancelToken((cancelRequest) => {
          cancel = cancelRequest
        }),
      }
    )

    window[CANCEL_TOKEN] = cancel

    return request
  },

  /**
   * @description get user by id
   * @returns {Promise}
   */
  getById(params) {
    let cancel
    const CANCEL_TOKEN = `${NAMESPACE}_GET_BY_ID`
    const request = resource.get(
      `${endpoint.v1.user.getUserById.replace(':id', params.id)}`,
      {
        cancelToken: new AxiosCancelToken((cancelRequest) => {
          cancel = cancelRequest
        }),
      }
    )

    window[CANCEL_TOKEN] = cancel

    return request
  },
}
