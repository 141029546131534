export default {
  v1: {
    user: {
      introduced_smart_assist_status:
        '/api/core/v1/users/introduced_smart_assist_status',
      getUserById: '/api/core/v1/users/:id',
    },
    harmonia: {
      is_form_submitted: '/api/core/v1/zoho/is_submitted',
    },
    chatbot: {
      gpt: {
        summary: {
          generate: '/v1/gpt/omnichannel/summaries/:room_id/histories',
          retrieve: '/v1/gpt/omnichannel/summaries/:room_id/histories',
          save: '/v1/gpt/omnichannel/summaries',
          get: '/v1/gpt/omnichannel/summaries/:room_id',
          remove: '/v1/gpt/omnichannel/summaries/:room_id',
        },
        paraphrases: {
          generate: '/v1/gpt/omnichannel/paraphrases/:room_id/histories',
          retrieve: '/v1/gpt/omnichannel/paraphrases/:room_id/histories',
          get: '/v1/gpt/omnichannel/paraphrases',
        },
        preferences: {
          get: '/v1/gpt/preferences',
          patch: '/v1/gpt/preferences',
        },
        historical: {
          get: '/v1/gpt/omnichannel/contacts/:id/room-summaries',
        },
        subsctiption: {
          get: '/v1/gpt/subscriptions',
        },
      },
      scorecard: {
        get: '/v1/scorecard_categories',
        getParameters: '/v1/scorecard_parameters',
        create: '/v1/scorecard_categories',
        update: '/v1/scorecard_categories/:id',
        delete: '/v1/scorecard_categories/:id',
        createByRoom: '/v1/gpt/omnichannel/agent_scorecards/:id',
        updateByRoom: '/v1/gpt/omnichannel/agent_scorecards/:id',
        getByRoom: '/v1/gpt/omnichannel/agent_scorecards/:id',
        preferences: '/v1/gpt/omnichannel/scorecard_preferences',
        getByRoomIds: '/v1/gpt/omnichannel/agent_scorecards',
      },
      airene: {
        get: '/v1/gpt/system_preferences',
      },
    },
  },
}
