import axios from 'axios'
import resource from '@/api/resource'

const AxiosCancelToken = axios.CancelToken
const NAMESPACE = 'CONTACT'

export default {
  /**
   * @description Check if contact can be deleted
   * @param {Object} payload
   * @returns {Promise}
   */
  isContactSafeDelete(id) {
    let cancel
    const CANCEL_TOKEN = `${NAMESPACE}_IS_CONTACT_SAFE_DELETE`
    const request = resource.get(
      `/api/core/v1/contact_objects/${id}/is_valid_to_delete`,
      {
        cancelToken: new AxiosCancelToken((cancelRequest) => {
          cancel = cancelRequest
        }),
      }
    )

    window[CANCEL_TOKEN] = cancel

    return request
  },

  /**
   * @description Delete contact
   * @param {Object} payload
   * @returns {Promise}
   */
  deleteContact(id) {
    let cancel
    const CANCEL_TOKEN = `${NAMESPACE}_DELETE_CONTACT`
    const request = resource.delete(`/api/core/v1/contact_objects/${id}`, {
      cancelToken: new AxiosCancelToken((cancelRequest) => {
        cancel = cancelRequest
      }),
    })

    window[CANCEL_TOKEN] = cancel

    return request
  },
}
