import axios from 'axios'
import resource from '@/api/resource'

const AxiosCancelToken = axios.CancelToken
const NAMESPACE = 'AGENT_WORKLOAD'

export default {
  /**
   * @description Retrieve current user workload
   * @returns {Promise}
   */
  currentUserWorkload() {
    let cancel
    const CANCEL_TOKEN = `${NAMESPACE}_RETRIEVE_CURRENT_USER_WORKLOAD`
    const request = resource.get('/api/core/v1/users/my_settings', {
      cancelToken: new AxiosCancelToken((cancelRequest) => {
        cancel = cancelRequest
      }),
    })

    window[CANCEL_TOKEN] = cancel

    return request
  },
}
