import axios from 'axios'
import { endpoint } from '@/common/constants'
import resource from '@/api/resourceChatbot'

const AxiosCancelToken = axios.CancelToken
const NAMESPACE = 'AIRENE'

export default {
  /**
   * @description Get Airene
   * @param {String} roomID
   * @returns {Promise}
   */
  getAireneInformation(params) {
    let cancel
    const CANCEL_TOKEN = `${NAMESPACE}_GET_INFORMATION`
    const request = resource.get(endpoint.v1.chatbot.airene.get, {
      params,
      cancelToken: new AxiosCancelToken((cancelRequest) => {
        cancel = cancelRequest
      }),
    })

    window[CANCEL_TOKEN] = cancel

    return request
  },
}
