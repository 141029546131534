import axios from 'axios'
import resource from '@/api/resource'

const AxiosCancelToken = axios.CancelToken
const NAMESPACE = 'CDN'

export default {
  /**
   * @description upload file
   * @param {File} file
   * @returns {Promise}
   */
  uploadFile(file, onUploadProgress, option = { filename: '' }) {
    let cancel
    const CANCEL_TOKEN = `${NAMESPACE}_UPLOAD_FILE`

    const formData = new FormData()
    if (option.filename) {
      formData.append('file', file, option.filename)
    } else {
      formData.append('file', file)
    }

    const request = resource.post(
      `/api/core/v1/file_uploader/message`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
        cancelToken: new AxiosCancelToken((cancelRequest) => {
          cancel = cancelRequest
        }),
      }
    )

    window[CANCEL_TOKEN] = cancel

    return request
  },
}
